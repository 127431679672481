export function transactionMessage(currentPaymentMode, transactionStatus, payatAccountNumber) {
  let paymentMethodLabel

  if (
    currentPaymentMode === 'by_cash' ||
    currentPaymentMode === 9 ||
    (currentPaymentMode === null && transactionStatus === 'confirmed')
  ) {
    paymentMethodLabel =
      "Please make payment at a Shoprite Checkers, Usave, House and Home, K'nect or OK Furniture store. If paying via Pay@, use the following Account Number: " +
      payatAccountNumber
  } else if (currentPaymentMode === 3 && transactionStatus !== 'open') {
    paymentMethodLabel = 'Payment made by supplied card'
  } else if (currentPaymentMode === 10 && transactionStatus !== 'open') {
    paymentMethodLabel = 'Payment made by Ozow'
  } else if (
    currentPaymentMode === null &&
    (transactionStatus === 'settled' || transactionStatus === 'released' || transactionStatus === 'complete')
  ) {
    paymentMethodLabel = 'Payment made in store.'
  } else {
    paymentMethodLabel = 'Payment failed'
  }
  return paymentMethodLabel
}
