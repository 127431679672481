/* eslint-disable indent */
import env from '.env'
import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, debounce, isEqual } from 'lodash'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from 'react-bootstrap'

/* Actions */
import {
  setSettings as setTradingSettings,
  setCurrentTradingLimit,
  setCurrentPaymentMode,
  setCurrentBeneficiary,
  setCurrentReceiptPaymentMethod,
  setCurrentCounterCurrency,
} from 'actions/trading'
/* Api */
import { index as cbasIndex } from 'api/bank_accounts/cw/cbas'
import {
  approve as approveTransactionPayment,
  confirm as transactionConfirm,
  show as transactionsShow,
  directDebits as transactionDirectDebits,
  approvals as transactionApprovals,
  createPayment as transactionCreatePayment,
  updatePayment as transactionUpdatePayment,
  destroyPayment as transactionDestroyPayment,
  notification as sendEmailNotification,
} from 'api/trading/cw/transactions'

import { getCheckoutID } from 'api/trading/cw/peach_payment'
import { getOzowDetails } from 'api/trading/cw/ozow'

import {
  update as transactionQuotesUpdate,
  create as transactionQuotesCreate,
  refresh as transactionQuotesRefresh,
} from 'api/trading/cw/transaction_quotes'
import { index as beneficiariesIndex } from 'api/recipients/cw/beneficiaries'
import { index as holdFundBeneficiariesIndex } from 'api/recipients/cw/hold_fund_beneficiaries'
import { settingsSpotTransactionAuthorizations } from 'api/trading/cw/settings'
/* Paths */
import { NEW_BENEFICIARY_FULL_PATH } from 'routes/paths/private/recipients'
import { NEW_FULL_PATH } from 'routes/paths/private/transactions'
/* Components */
import queryUrl from 'components/utils/query_url'
import Beneficiary from './beneficiary'
import TransactionConfirmedModal from './modals/transaction_confirmed'
import PersonalDetailsConfirm from '../../../../users/confirm-customer-details/personal_details_confirm'
import OtpModal from 'components/pages/shared/modals/otp'
import TransactionProcessing from 'components/pages/shared/modals/transaction_processing'
import BeneficiaryFormModal from 'components/pages/shared/modals/beneficiary_form'
import SameCurrencyModal from './modals/same_currency'
import QuoteFailed from './modals/quote_failed'
import PeachPaymentModal from './modals/peach_payment'
import InstantQuote from './instant_quote'
import ExchangeRate from './exchange_rate'
import Ozow from './modals/ozow'
/** HOC */
import WithCurrentUser from 'components/HOC/with_current_user'
/* Utils */
import transactionInitializer from 'data/initializers/transaction'
import customerBankAccountInitializer from 'data/initializers/customer_bank_account'
import beneficiaryInitializer from 'data/initializers/beneficiary'
/* Helpers */
import Checkbox from 'components/helpers/form_fields/checkbox'
import { userLocalStorage } from 'config/local_storage'
import './styles.scss'

const MAX_CREATE_TRANSACTION_ERROR_COUNT = 10

// TODO:
// rl-pending-payments
/* <rl-accepted-transactions></rl-accepted-transactions> AKa: Payment History */

class NewPayment extends Component {
  payoutSettingsRef = createRef()
  state = {
    acceptTransactionPage: false,
    approvalDetails: {},
    bankAccountOptions: [],
    beneficiary: {},
    beneficiaryList: [],
    beneficiaryOptions: [],
    beneficiaryTransaction: {},
    beneficiaryTransactionReference: '',
    cashExternalAddress: {},
    createTransactionErrorCount: 0,
    currencyCode: null,
    customerBankAccount: customerBankAccountInitializer,
    customerBankAccountList: [],
    disableBaseCurrencyAmount: false,
    errors: {},
    fetching: {
      CBAList: true,
      initialLoad: true,
      settlementPreferences: true,
    },
    isAbleToTransact: false,
    isCheckoutSelected: false,
    isOneToOneDeal: false,
    // When counter_ccy and base_ccy are the same
    isOneToOneDealWithAcknowledgement: false,
    isTransactionBeingCreated: false,
    isTransactionUpdating: false,
    modal: {
      beneficiary: false,
      beneficiaryForm: false,
      confirmation: false,
      otp: false,
      ozow: false,
      peachPayment: false,
      personalDetails: false,
      quoteFailed: false,
      sameCurrency: false,
      scamInformation: false,
      transactionConfirmed: false,
      transactionProcessing: false,
    },
    ozowDetails: {},
    paymentApprovalRequired: false,
    peachCheckoutId: null,
    settlementDetails: {},
    settlementPreferences: {},
    transaction: transactionInitializer,
    transactionAmountCalculationBasedOn: '',
    transactionDetails: {},
    transactionSOF: '',
    withdraw: false,
  }

  updateTransaction = debounce(() => {
    const { transaction } = this.state
    const data = { transaction_quote: this.transactionQuoteParams() }

    transactionQuotesUpdate({
      data,
      transactionId: transaction.transaction_id,
    })
      .then(response => {
        const { isApplicationBank } = this.props

        // Condition to avoid update user input if a previous request was made
        // For example:
        // User typed 10.00 on the input and a request was made
        // Before receiving the response from server, he typed a new value: 20.00
        // This condition will avoid that the input changes its value to 10.00

        // NOTE: Disable this functionality as it stops the API response updating the amounts.
        // if (!this.doesInputValueMatchWithRequestValue(response)) {
        //   this.setState({ isTransactionUpdating: false })
        //   return
        // }
        response && this.fixResponse(response?.data)
        this.setState({ transaction: response.data }, () => {
          isApplicationBank && this.createtransactionsDirectDebits()
        })
        this.setTransactionDetails(response?.data)
      })
      .catch(error => this.setState({ errors: error?.response?.data?.errors }))
      .finally(() => {
        this.setState({ isTransactionUpdating: false })
      })
  }, 1000)

  createTransaction = debounce(() => {
    const params = { transaction_quote: this.transactionQuoteParams() }
    const { createTransactionErrorCount } = this.state
    this.setState({
      isTransactionBeingCreated: true,
      isTransactionUpdating: true,
    })
    transactionQuotesCreate(params)
      .then(response => {
        this.setState(
          state => ({
            createTransactionErrorCount: 0,
            isTransactionBeingCreated: false,
            isTransactionUpdating: false,
            transaction: {
              ...response.data,
              ...(state.transaction.base_currency_amount
                ? {
                    base_currency_amount: state.transaction.base_currency_amount,
                  }
                : {}),
              ...(state.transaction.counter_currency_amount
                ? {
                    counter_currency_amount: state.transaction.counter_currency_amount,
                  }
                : {}),
            },
          }),
          this.setTransactionDetails(response?.data),
          this.fetchApprovalsDetails,
          !this.doesInputValueMatchWithRequestValue(response) && this.updateTransaction(),
        )
      })
      .catch(error => {
        this.setState(state => ({
          createTransactionErrorCount: state.createTransactionErrorCount + 1,
          errors: error?.response?.data?.errors || {},
        }))
        if (
          error?.response?.data?.code !== 'FTQ01001' &&
          createTransactionErrorCount < MAX_CREATE_TRANSACTION_ERROR_COUNT
        ) {
          this.createTransaction()
        }
      })
      .finally(() => {
        this.setState({
          isTransactionBeingCreated: false,
          isTransactionUpdating: false,
        })
      })
  }, 1000)

  componentDidMount() {
    const url = this.props.location
    const params = queryUrl.extract(url.search)

    // reset state
    this.props.setCurrentCounterCurrency({})
    this.props.setCurrentReceiptPaymentMethod({})

    this.setState(
      {
        beneficiary: {
          ...beneficiaryInitializer,
          id: parseInt(params.get('beneficiaryId')) || null,
        },
        currencyCode: params.get('currencyCode'),
        customerBankAccount: {
          ...customerBankAccountInitializer,
          id: parseInt(params.get('customerBankAccountId')) || null,
        },
        withdraw: url.search.includes('withdraw'),
      },
      () => {
        this.initializeBeneficiaries()
        this.state.withdraw && this.setupWithdraw()
      },
    )
  }

  shouldComponentUpdate(_, nextState) {
    // Prevents rendering if errors state is undefined
    if (!nextState.errors) {
      this.setState({ errors: {} })
      return false
    }
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentReceiptPaymentMethod, tradingSettings, isApplicationBank } = this.props
    const payNowBeneficiary = userLocalStorage.get('currentRecipient')
    const {
      transaction,
      transactionSOF,
      beneficiaryTransactionReference,
      beneficiary,
      createTransactionErrorCount,
      customerBankAccount,
      beneficiaryTransaction,
      customerBankAccountList,
      settlementPreferences,
      isTransactionBeingCreated,
    } = this.state
    let isDestroyingBeneficiary = false

    if (!isEmpty(tradingSettings) && isEmpty(prevProps.tradingSettings)) {
      this.initializeBeneficiaries()
    }

    if (payNowBeneficiary && !isEmpty(this.state.beneficiaryList)) {
      this.setBeneficiaryId(payNowBeneficiary.id)
      userLocalStorage.remove('currentRecipient')
    }

    if (
      (!isEqual(prevState.settlementPreferences, settlementPreferences) ||
        prevState.transaction.base_currency_code !== transaction.base_currency_code) &&
      settlementPreferences?.from_customer_bank_account?.enabled
    ) {
      this.customerBankAccountsIndex()
    }

    if (
      transaction.base_currency_code !== prevState.transaction.base_currency_code ||
      transaction.counter_currency_code !== prevState.transaction.counter_currency_code
    ) {
      this.onCurrencyChange()
    }
    if (
      beneficiaryTransaction?.id &&
      !this.state.modal.transactionConfirmed &&
      ((!!parseFloat(prevState.transaction.base_currency_amount) && !parseFloat(transaction.base_currency_amount)) ||
        (!!parseFloat(prevState.transaction.counter_currency_amount) &&
          !parseFloat(transaction.counter_currency_amount)))
    ) {
      isDestroyingBeneficiary = true
      this.destroyBeneficiaryTransactions().then(() => {
        isDestroyingBeneficiary = false
      })
    }

    if (beneficiary.id && transaction.transaction_id && this.isCurrencyAndAmountEntered()) {
      if (beneficiaryTransaction.id && prevState.beneficiary.id !== beneficiary.id) {
        isDestroyingBeneficiary = true
        this.destroyBeneficiaryTransactions().then(() => {
          isDestroyingBeneficiary = false
        })
      } else if (
        beneficiaryTransaction.id &&
        prevState.transaction.transaction_id === transaction.transaction_id &&
        prevState.beneficiaryTransactionReference !== beneficiaryTransactionReference
      ) {
        this.updateBeneficiaryTransactions(isDestroyingBeneficiary)
      }
    }
    // Update Source of funds
    if (!!prevState.transactionSOF && prevState.transactionSOF !== transactionSOF) {
      this.setState({ isTransactionUpdating: true })
      this.updateTransaction()
    }
    // When data finishes loading it will create a transaction
    if (
      !isTransactionBeingCreated &&
      !transaction.transaction_id &&
      this.isCurrencyAndAmountEntered() &&
      !!currentReceiptPaymentMethod?.value &&
      createTransactionErrorCount < MAX_CREATE_TRANSACTION_ERROR_COUNT
    ) {
      this.createTransaction()
    }

    if (this.shouldTransactionUpdate(prevProps, prevState)) {
      this.setState({ isTransactionUpdating: true })
      this.updateTransaction()
    }

    if (beneficiary.id !== prevState.beneficiary.id || (beneficiary.id && beneficiary.initializer)) {
      this.onBeneficiaryChange(isDestroyingBeneficiary)
    }

    if (prevState.transaction.base_currency_code !== transaction.base_currency_code) {
      this.props.setTradingSettings({
        currency_code: transaction.base_currency_code,
      })
    }

    if (isApplicationBank) {
      // Set the CBA when the id of it was sent on the url (See componentDidMount for more information on customerBankAccountId)
      if (customerBankAccount.id && isEmpty(prevState.customerBankAccountList) && !isEmpty(customerBankAccountList)) {
        this.setState(
          {
            customerBankAccount:
              customerBankAccountList.find(account => parseInt(account.id) === parseInt(customerBankAccount.id)) || {},
          },
          this.configureNewCustomerBankAccount,
        )
      }

      if (prevState.customerBankAccount.id !== customerBankAccount.id) {
        this.configureNewCustomerBankAccount()
      }
    }
  }

  onAcceptanceOfOneToOneDeal = () =>
    this.setState({
      isOneToOneDealWithAcknowledgement: true,
      modal: {
        ...this.state.modal,
        sameCurrency: false,
      },
    })

  onSellCurrencyChange = baseCurrencyCode =>
    this.setState(state => ({
      transaction: {
        ...state.transaction,
        base_currency_code: baseCurrencyCode,
      },
    }))

  onBuyCurrencyChange = ccyOption => {
    this.setState(state => ({
      counter_currency_code: ccyOption.currency_code,
      counter_currency_country_code: ccyOption.country_code,
      transaction: {
        ...state.transaction,
        counter_currency_code: ccyOption.currency_code,
        counter_currency_country_code: ccyOption.country_code,
      },
    }))
    this.props.setCurrentCounterCurrency({
      counterCurrencyCode: ccyOption.currency_code,
      counterCurrencyCountryCode: ccyOption.country_code,
    })
    // reset collection method
    this.props.setCurrentReceiptPaymentMethod({})
  }

  onCurrencyAmountChange = currencyAmountName => value => {
    const { transaction, withdraw, customerBankAccount } = this.state

    if (withdraw && currencyAmountName === 'counter_currency_amount') {
      const amount = transaction.counter_currency_amount
      const balance = customerBankAccount.balance - parseFloat(amount)
      this.setState({ balance })
    }

    const contraryCurrencyAmountName =
      currencyAmountName === 'base_currency_amount' ? 'counter_currency_amount' : 'base_currency_amount'
    this.setState(state => ({
      transaction: {
        ...state.transaction,
        [contraryCurrencyAmountName]: !parseFloat(value) ? '' : transaction[contraryCurrencyAmountName],
        [currencyAmountName]: value?.toString(),
      },
      transactionAmountCalculationBasedOn: !parseFloat(value) ? '' : currencyAmountName,
    }))
  }

  onCurrencyChange = () => {
    const { transaction, modal, withdraw } = this.state
    const { tradingSettings } = this.props
    const isOneToOneDeal = transaction.counter_currency_code === transaction.base_currency_code
    this.setState({
      isOneToOneDeal,
      isOneToOneDealWithAcknowledgement: false,
    })
    if (isOneToOneDeal) {
      const newTransaction = {
        ...transaction,
        base_currency_code: transaction.counter_currency_code,
        counter_currency_code: transaction.base_currency_code,
      }

      this.setState({
        disableBaseCurrencyAmount: false,
        modal: {
          ...modal,
          sameCurrency: !withdraw && tradingSettings.same_currency_transaction?.prompt_on_same_currencies_selection,
        },
        transaction: newTransaction,
      })
    }
  }

  onNewBeneficiaryAdded = beneficiary =>
    this.setState({
      beneficiaryList: this.appendFullNameToBeneficiaries(this.state.beneficiaryList.concat([beneficiary])),
    })

  onBeneficiaryChange = isDestroyingBeneficiary => {
    const { beneficiary, transaction, modal, beneficiaryTransaction, beneficiaryList } = this.state
    if (!beneficiary?.id || !beneficiaryList?.length || isDestroyingBeneficiary) {
      return
    }
    if (beneficiary.id === 'newBeneficiary') {
      this.setState({
        beneficiary: beneficiaryInitializer,
        beneficiaryTransaction: { ...beneficiaryTransaction, reference: '' },
        modal: { ...modal, beneficiaryForm: true },
      })
    } else {
      const completeBeneficiaryInfo = beneficiaryList.find(bene => bene.id === beneficiary.id)

      this.setState({
        beneficiary: completeBeneficiaryInfo,
        beneficiaryTransaction: {
          ...beneficiaryTransaction,
          reference: completeBeneficiaryInfo.payment_reference,
        },
        errors: {},
        transaction: {
          ...transaction,
          counter_currency_code: completeBeneficiaryInfo.currency_code,
        },
      })
      this.createBeneficiaryTransactions()
    }
  }

  setSettlementPrefs = settlementPreferences => this.setState({ settlementPreferences })

  onSourceOfFundsChange = transactionSOF => this.setState({ transactionSOF })

  setTransactionDetails = transactionDetails => this.setState({ transactionDetails })

  setCustomerBankAccount = customerBankAccount => this.setState({ customerBankAccount })

  setBeneficiaryId = beneficiaryId => {
    if (beneficiaryId === 'newBeneficiary') {
      return this.props.history.push(NEW_BENEFICIARY_FULL_PATH)
    }
    this.setState({
      beneficiary: {
        ...this.state.beneficiary,
        id: beneficiaryId,
      },
    })
  }

  setBeneficiaryTransactionReference = beneficiaryTransactionReference =>
    this.setState({ beneficiaryTransactionReference })

  handleTransactionConfirmation = () => {
    const { transaction } = this.state
    const { paymentMode } = this.props
    this.hideModal('confirmation')
    this.hideModal('quoteFailed')
    this.showModal('transactionProcessing')
    transactionsShow(transaction.transaction_id)
      .then(response => {
        this.setState({
          settlementDetails: {
            payat_account_number: response.data.payat_account_number,
            receipt_payment_mode_id: response.data.receipt_payment_mode_id,
          },
          transaction: response.data.quote,
        })
        if (paymentMode && paymentMode !== 'by_cash') {
          this.handleNonCashTransactionConfirmation()
        } else {
          this.handleCashTransactionConfirmation()
        }
      })
      .catch(() => {
        toast.error('Error refreshing transaction')
        this.hideModal('transactionProcessing')
      })
  }

  handleNonCashTransactionConfirmation = (otp = '') => {
    const { transaction, beneficiary } = this.state
    const { paymentMode, setCurrentBeneficiary } = this.props

    return transactionConfirm(transaction.transaction_id, otp)
      .then(res => {
        setCurrentBeneficiary(beneficiary)
        this.hideModal('transactionProcessing')
        return res?.status === 200 && this.showModal(paymentMode === 'by_instant_eft' ? 'ozow' : 'peachPayment')
      })
      .catch(error => {
        const errors = error?.response?.data?.errors || {}
        const containsOTP = isEmpty(errors)
          ? false
          : Array.isArray(errors?.status)
          ? errors?.status?.find(errorMessage => errorMessage.includes('OTP'))
          : errors?.status?.includes('OTP')
        this.showModal(containsOTP ? 'otp' : 'quoteFailed')
        this.setState({ errors })
      })
      .finally(() => {
        const tradingLimitCurrency = env.tradingLimitCurrency || 'ZAR'
        this.props.setCurrentTradingLimit(tradingLimitCurrency)
        this.hideModal('transactionProcessing')
      })
  }

  handleCashTransactionConfirmation = (otp = '') => {
    const { transaction } = this.state
    return transactionConfirm(transaction.transaction_id, otp)
      .then(res => {
        // Can run only after transaction is confirmed
        sendEmailNotification(transaction.transaction_id).catch(err => toast.error(err?.error?.description))
        res?.status === 200 && this.showModal('transactionConfirmed')
      })
      .catch(error => {
        const errors = error?.response?.data?.errors || {}
        const containsOTP = isEmpty(errors)
          ? false
          : Array.isArray(errors?.status)
          ? errors?.status?.find(errorMessage => errorMessage.includes('OTP'))
          : errors?.status?.includes('OTP')
        this.showModal(containsOTP ? 'otp' : 'quoteFailed')
        this.setState({ errors })
      })
      .finally(() => {
        this.hideModal('transactionProcessing')
        const tradingLimitCurrency = env.tradingLimitCurrency || 'ZAR'
        this.props.setCurrentTradingLimit(tradingLimitCurrency)
      })
  }

  appendFullNameToBeneficiaries = beneficiaries =>
    beneficiaries.map(beneficiary => {
      if (beneficiary.linked_to_cba) {
        beneficiary.full_name = `${beneficiary.currency_code} - ${beneficiary.account.account_number}`
      } else {
        beneficiary.full_name = `${beneficiary.address.name}`.replace(/\s+/g, ' ')
      }
      return beneficiary
    })

  // TODO: ask how this works and depending on the answer put it on the ComponentDidUpdate
  createtransactionsDirectDebits = () => {
    const { customerBankAccount, transaction } = this.state
    if (customerBankAccount) {
      transactionDirectDebits({
        data: {
          direct_debit: {
            customer_bank_account_id: customerBankAccount.id,
            note_attributes: { note: '' },
          },
          transaction_id: transaction.transaction_id,
        },
        transactionId: transaction.transaction_id,
      })
        .then(() => {
          this.setState({
            transaction: {
              ...transaction,
              transactions_direct_debit_added: true,
            },
          })
        })
        .catch(error => this.setState({ errors: error?.response?.data?.errors }))
    }
  }

  configureNewCustomerBankAccount = () => {
    const { customerBankAccount } = this.state

    // If it does not have currency_code
    // it means CBA data is being requested to the server
    if (!customerBankAccount.currency_code) {
      return
    }

    this.setState(state => ({
      transaction: {
        ...state.transaction,
        base_currency_code: customerBankAccount.currency_code,
      },
    }))
  }

  customerBankAccountsIndex() {
    const { transaction, fetching } = this.state
    this.setState({
      fetching: {
        ...fetching,
        CBAList: true,
      },
    })
    cbasIndex(transaction.base_currency_code).then(response => {
      this.setState(
        {
          customerBankAccountList: response.data,
          fetching: {
            ...fetching,
            CBAList: false,
          },
        },
        () => {
          const { customerBankAccountList, settlementPreferences } = this.state
          let { customerBankAccount } = this.state
          if (customerBankAccountList.find(cba => cba.id === customerBankAccount.id)) {
            return
          }
          if (
            settlementPreferences.from_customer_bank_account?.enabled &&
            settlementPreferences.from_customer_bank_account?.customer_bank_account_id
          ) {
            customerBankAccount =
              customerBankAccountList.find(
                cba => settlementPreferences.from_customer_bank_account.customer_bank_account_id === cba.id,
              ) || customerBankAccountList[0]
          } else if (customerBankAccountList.length === 1) {
            customerBankAccount = customerBankAccountList[0]
          }
          this.setState({ customerBankAccount })
        },
      )
    })
  }

  isCurrencyAndAmountEntered = () => {
    const { transaction } = this.state
    return (
      transaction.base_currency_code &&
      transaction.counter_currency_code &&
      (transaction.counter_currency_amount > 0 || transaction.base_currency_amount > 0)
    )
  }

  showExchangeRate = () => {
    const { currentReceiptPaymentMethod } = this.props
    return !!currentReceiptPaymentMethod?.value && this.isCurrencyAndAmountEntered()
  }

  initializeBeneficiaries = () => {
    const { tradingSettings } = this.props
    if (isEmpty(tradingSettings)) {
      return
    }

    if (tradingSettings.beneficiary_payments?.approvals_enabled) {
      settingsSpotTransactionAuthorizations().then(response => {
        const authorization = response.data
        this.setState({
          paymentApprovalRequired:
            tradingSettings.beneficiary_payments?.approvals_required > 1 ||
            (tradingSettings.beneficiary_payments?.approvals_required === 1 && !authorization.approve_payment),
        })
      })
    }

    const fetchUserBeneficiaries = () => {
      const params = {
        per_page: 250,
        q: {
          currency_code_eq: this.state.currencyCode,
          status_eq: 'approved_by_client',
        },
      }
      this.setState({ isBeneficiaryListLoading: true })
      return new Promise((resolve, reject) => {
        beneficiariesIndex(params)
          .then(response =>
            this.setState({ beneficiaryList: this.appendFullNameToBeneficiaries(response?.data) }, resolve),
          )
          .catch(error => reject(error))
          .finally(() => {
            this.setState({ isBeneficiaryListLoading: false })
          })
      })
    }

    const fetchUserHoldFundBeneficiaries = () => {
      const params = {
        page: 1,
        per_page: 250,
        q: {
          currency_code_eq: this.state.currencyCode,
        },
      }
      return new Promise((resolve, reject) => {
        holdFundBeneficiariesIndex(params)
          .then(response => {
            const hfoaBeneficiaries = response.data || []
            hfoaBeneficiaries.forEach(beneficiary => {
              beneficiary.address.name = `${beneficiary.currency_code} ${beneficiary.address.name}`
            })
            this.setState(
              state => ({
                beneficiaryList: this.appendFullNameToBeneficiaries(state.beneficiaryList.concat(hfoaBeneficiaries)),
              }),
              resolve,
            )
          })
          .catch(error => {
            reject(error)
          })
      })
    }

    Promise.all([fetchUserBeneficiaries(), fetchUserHoldFundBeneficiaries()])
      .then(() => this.toggleFetching('initialLoad'))
      .catch(error => toast.error(error.message))
  }

  hideModal = name => this.setState(state => ({ modal: { ...state.modal, [name]: false } }))

  oneToOneDealAllowed = () => this.props.tradingSettings?.same_currency_transaction?.enabled

  refreshTransaction = () => {
    const { isApplicationBank } = this.props
    const { transaction } = this.state

    return transactionQuotesRefresh(transaction.transaction_id).then(response => {
      response && this.fixResponse(response?.data)
      this.setState(
        {
          transaction: {
            ...response?.data,
            transactions_direct_debit_added: transaction.transactions_direct_debit_added,
          },
        },
        () => isApplicationBank && this.createtransactionsDirectDebits(),
      )
    })
  }

  showModal = name => this.setState(state => ({ modal: { ...state.modal, [name]: true } }))

  proceedWithTransfer = () => {
    const { transaction } = this.state
    const { currentReceiptPaymentMethod, goNextPage } = this.props

    if (!transaction.base_currency_amount || !transaction.counter_currency_amount) {
      return toast.error('Please Add all Relevant transaction details')
    }

    if (!currentReceiptPaymentMethod?.value) {
      return this.props.setCurrentReceiptPaymentMethod({ error: 'Please select a payment method' })
    }

    this.setState({ errors: {} })
    goNextPage()
  }

  continueToPayment = () => {
    this.proceedWithTransfer()

    if (!this.state.beneficiary.id) {
      return toast.error('Please select or create a new beneficiary')
    }

    this.setState({ acceptTransactionPage: true })

    this.setState({ errors: {} })
  }

  shouldTransactionUpdate = (prevProps, prevState) => {
    const { beneficiary, isTransactionBeingCreated, transaction } = this.state
    const { currentReceiptPaymentMethod } = this.props
    const baseCurrencyAmountChanged = prevState.transaction.base_currency_amount !== transaction.base_currency_amount
    const counterCurrencyAmountChanged =
      prevState.transaction.counter_currency_amount !== transaction.counter_currency_amount

    if (
      (prevState.transactionAmountCalculationBasedOn === 'base_currency_amount' && counterCurrencyAmountChanged) ||
      (prevState.transactionAmountCalculationBasedOn === 'counter_currency_amount' && baseCurrencyAmountChanged)
    ) {
      // Abort update if the read-only amount field has changed.
      return false
    }

    return (
      !isTransactionBeingCreated &&
      !!currentReceiptPaymentMethod?.value &&
      this.isCurrencyAndAmountEntered() &&
      transaction.transaction_id &&
      (baseCurrencyAmountChanged ||
        counterCurrencyAmountChanged ||
        prevState.transaction.base_currency_code !== transaction.base_currency_code ||
        prevState.transaction.counter_currency_code !== transaction.counter_currency_code ||
        prevState.beneficiary.id !== beneficiary.id ||
        prevProps.currentReceiptPaymentMethod?.value !== currentReceiptPaymentMethod?.value)
    )
  }

  // TODO: test on cypress when it's withdraw
  setupWithdraw = () => {
    const { transaction, currencyCode } = this.state
    this.setState({
      isOneToOneDeal: true,
      transaction: {
        ...transaction,
        base_currency_code: currencyCode,
        counter_currency_code: currencyCode,
      },
    })
  }

  toggleFetching = name => {
    const { fetching } = this.state
    this.setState(state => ({
      fetching: {
        ...fetching,
        [name]: !state.fetching[name],
      },
    }))
  }

  // ============== BENEFICIARY TRANSACTIONS ACTIONS ==============

  updateBeneficiaryTransactions = isDestroyingBeneficiary => {
    const { transaction, beneficiaryTransaction } = this.state
    if (isDestroyingBeneficiary) {
      return
    }

    const params = {
      beneficiaryTransactionId: beneficiaryTransaction.id,
      data: this.beneficiaryTransactionsParams(),
      transactionId: transaction.transaction_id,
    }

    // TODO: for some reason when error is set on catch method we get a multiple call from didupdate error
    transactionUpdatePayment(params).then(response =>
      this.setState(
        {
          beneficiaryTransaction: response?.data,
          transaction: { ...this.state.transaction, fee: response?.data?.fees?.fee },
        },
        this.fetchApprovalsDetails,
      ),
    )
  }

  destroyBeneficiaryTransactions = () => {
    const { transaction, beneficiaryTransaction } = this.state
    const params = {
      beneficiaryTransactionId: beneficiaryTransaction.id,
      transactionId: transaction.transaction_id,
    }

    return transactionDestroyPayment(params)
      .then(response => {
        this.setState(
          {
            beneficiaryTransaction: response?.data,
            transaction: { ...this.state.transaction, fee: '' },
          },
          () => this.isCurrencyAndAmountEntered() && this.createBeneficiaryTransactions(),
        )
      })
      .catch(error => this.setState({ errors: error?.response?.data?.errors }))
  }

  createBeneficiaryTransactions = () => {
    const { transaction } = this.state

    const params = {
      data: this.beneficiaryTransactionsParams(),
      transactionId: transaction.transaction_id,
    }

    transactionCreatePayment(params)
      .then(response =>
        this.setState(
          {
            beneficiaryTransaction: response?.data,
            transaction: { ...this.state.transaction, fee: response?.data?.fees?.fee },
          },
          this.refreshTransaction,
        ),
      )
      .catch(error => this.setState({ errors: error?.response?.data?.errors }))
  }

  beneficiaryTransactionsParams = () => {
    const { transaction, beneficiaryTransactionReference, beneficiary, transactionSOF } = this.state
    return {
      beneficiary_transaction: {
        amount: transaction.counter_currency_amount,
        beneficiary_id: String(beneficiary.id),
        reference: beneficiaryTransactionReference || '',
        risk_assesment_information_attributes: { sources_of_funds: transactionSOF || '' },
      },
    }
  }

  // ============== QUOTES TRANSACTIONS ACTIONS ==============

  doesInputValueMatchWithRequestValue = response => {
    const { transactionAmountCalculationBasedOn, transaction } = this.state
    return transactionAmountCalculationBasedOn === 'base_currency_amount'
      ? parseFloat(transaction.base_currency_amount) === parseFloat(response.data.base_currency_amount)
      : parseFloat(transaction.counter_currency_amount) === parseFloat(response.data.counter_currency_amount)
  }

  fixResponse = res =>
    ['base_currency_amount', 'counter_currency_amount'].forEach(
      key => (res[key] = parseFloat(res[key]).toFixed(2).toString()),
    )

  transactionQuoteParams = () => {
    const { currentCounterCurrency, currentReceiptPaymentMethod } = this.props
    const {
      transaction,
      transactionAmountCalculationBasedOn,
      isOneToOneDeal,
      withdraw,
      beneficiary,
      transactionSOF,
      paymentModeValue,
    } = this.state

    const quoteParams = {
      base_currency_code: transaction?.base_currency_code,
      beneficiary_country_code: currentCounterCurrency?.counterCurrencyCountryCode,
      counter_currency_code: transaction?.counter_currency_code || beneficiary?.currency_code,
      funding_source_id: transactionSOF,
    }

    if (isOneToOneDeal) {
      quoteParams.same_currency_deal_type = withdraw ? 'Withraw Held Funds' : 'Transfer'
    }

    if (transactionAmountCalculationBasedOn === 'counter_currency_amount') {
      quoteParams.counter_currency_amount = transaction.counter_currency_amount || 0
    } else {
      quoteParams.base_currency_amount = transaction.base_currency_amount || 0
    }

    if (paymentModeValue) {
      quoteParams.receipt_payment_mode_id = paymentModeValue
    }

    if (currentReceiptPaymentMethod?.id) {
      quoteParams.renumeration_type_id = currentReceiptPaymentMethod.id
    }

    return quoteParams
  }

  fetchApprovalsDetails = () => {
    const { transaction } = this.state
    transactionApprovals(transaction.transaction_id)
      .then(response => {
        const approvalDetails = response.data
        this.setState({ approvalDetails }, () => {
          approvalDetails.payment_confirmed
            ? this.setState({ paymentApprovalRequired: false })
            : this.fetchSpotTransactionAuthorizations()
        })
      })
      .catch(error => this.setState({ errors: error?.response?.data?.errors }))
  }

  fetchSpotTransactionAuthorizations = () => {
    const { approvalDetails } = this.state
    settingsSpotTransactionAuthorizations()
      .then(response => {
        const authorization = response.data
        this.setState(
          { paymentApprovalRequired: approvalDetails.payment_approvals?.[0]?.approvals_pending > 0 },
          () => {
            const { paymentApprovalRequired, beneficiaryTransaction } = this.state
            if (
              !isEmpty(beneficiaryTransaction.approvals) &&
              paymentApprovalRequired &&
              authorization.approve_payment
            ) {
              this.setState({ paymentApprovalRequired: false })
            }
          },
        )
      })
      .catch(error => this.setState({ errors: error?.response?.data?.errors }))
  }

  // ============== TRANSACTION CONFIRMATION ACTIONS ==============

  confirmTransaction = () => {
    const { approvalDetails, beneficiaryTransaction } = this.state
    const paymentApproval = approvalDetails.payment_approvals?.[0]
    const paymentConfirmed = paymentApproval?.approvals_enabled && !paymentApproval?.approved
    if (paymentConfirmed) {
      if (beneficiaryTransaction.approvals) {
        this.beneficiaryTransactionsApprove()
      } else {
        toast.success('Payment is already confirmed')
        this.reloadComponent()
      }
    } else {
      this.handleTransactionConfirmation()
    }
  }

  beneficiaryTransactionsApprove = async () => {
    const { transaction, beneficiaryTransaction } = this.state
    try {
      await approveTransactionPayment(transaction.transaction_id, beneficiaryTransaction.id)
      const response = await transactionApprovals(transaction.transaction_id)
      response?.data?.payments_confirmed ? this.handleTransactionConfirmation() : this.reloadComponent()
    } catch (error) {
      this.hideModal('transactionProcessing')
      toast.error(error.errors)
    }
  }

  reloadComponent = () => this.props.reloadComponent()

  handleGetTransactionDetails = async () => {
    const { transaction } = this.state
    const { paymentMode } = this.props

    if (paymentMode !== 'by_cash') {
      try {
        await Promise.all([
          ...(paymentMode === 'by_instant_eft'
            ? [
                getOzowDetails(transaction.transaction_id, {
                  cancel_url: `#${transaction.transaction_id}/status`,
                  error_url: `#${transaction.transaction_id}/status`,
                  is_test: env?.ozow?.is_test,
                  success_url: `#${transaction.transaction_id}/status`,
                }).then(res => res?.data && this.setState({ ozowDetails: res?.data })),
              ]
            : []),
          ...(paymentMode === 'by_payment_card'
            ? [
                getCheckoutID(transaction.transaction_id).then(
                  res => res?.status === 200 && this.setState({ peachCheckoutId: res?.data }),
                ),
              ]
            : []),
        ])
      } catch (error) {
        this.setState(error?.response?.data?.errors?.description || {})
      }
    }

    this.setState({ isCheckoutSelected: true })
  }

  handleAcceptTransaction = () => {
    const { beneficiary, isAbleToTransact } = this.state
    if (!isAbleToTransact) {
      return toast.error('Please confirm that your personal details are up to date')
    }

    if (beneficiary.renumeration_type_name === 'Cash External' && this.payoutSettingsRef.current && isAbleToTransact) {
      this.payoutSettingsRef.current.handleSubmit()
      this.payoutSettingsRef.current.isValid &&
        this.setState(state => ({ ...state, cashExternalAddress: this.payoutSettingsRef.current.values }))
    }

    this.confirmTransaction()
  }

  render() {
    const {
      isApplicationBank,
      userAddressDetails,
      displayPage,
      paymentMode,
      setCurrentBeneficiary,
      setCurrentPaymentMode,
    } = this.props
    const {
      errors,
      modal,
      acceptTransactionPage,
      settlementPreferences,
      isAbleToTransact,
      isCheckoutSelected,
    } = this.state

    return (
      <React.Fragment>
        {displayPage === 1 && (
          <div className="content-container pb-0">
            <h1 className="page-title white">
              <span className="bar-1">Send money</span>
            </h1>
          </div>
        )}
        <InstantQuote
          {...this.state}
          displayPage={displayPage}
          isApplicationBank={isApplicationBank}
          oneToOneDealAllowed={this.oneToOneDealAllowed}
          setCustomerBankAccount={this.setCustomerBankAccount}
          toggleFetching={this.toggleFetching}
          setSettlementPrefs={this.setSettlementPrefs}
          onSellCurrencyChange={this.onSellCurrencyChange}
          onBuyCurrencyChange={this.onBuyCurrencyChange}
          onCurrencyAmountChange={this.onCurrencyAmountChange}
          onSourceOfFundsChange={this.onSourceOfFundsChange}
          showExchangeRate={this.showExchangeRate()}
          showConfirmationModal={this.showConfirmationModal}
          proceedWithTransfer={this.proceedWithTransfer}
        />
        {displayPage === 2 && (
          <div className="container-send-money beneficiaries">
            <div className="content-container-send send-money">
              <div className="step-1">
                <h2 className="heading panel-header">Who are you sending money to?</h2>
                <div className="form-block w-form">
                  <Beneficiary
                    {...this.state}
                    innerRef={this.payoutSettingsRef}
                    oneToOneDealAllowed={this.oneToOneDealAllowed}
                    onCurrencyAmountChange={this.onCurrencyAmountChange}
                    setBeneficiaryTransactionReference={this.setBeneficiaryTransactionReference}
                    setBeneficiaryId={this.setBeneficiaryId}
                  />
                </div>
                <div
                  to="#"
                  className={`cta-button secondary ${acceptTransactionPage ? 'grey' : ''} w-inline-block`}
                  onClick={() => {
                    // clear beneficiary state
                    setCurrentBeneficiary(null)
                    this.props.history.push(NEW_BENEFICIARY_FULL_PATH)
                  }}
                  disabled={acceptTransactionPage}>
                  + New beneficiary
                </div>
                <a
                  className="cta-button w-button"
                  style={{ display: acceptTransactionPage ? 'none' : 'flex', marginTop: '20px' }}
                  onClick={this.continueToPayment}>
                  Continue to payment
                </a>
              </div>
              <div className="step-2" style={{ display: acceptTransactionPage ? 'block' : 'none' }}>
                <div className="step-2-content">
                  <div className="divider" />
                  <h2 className="heading panel-header">How would you like to pay?</h2>
                  <div className="form-block w-form">
                    <label className="control-label field-label">Choose your payment method</label>
                    {Object.keys(settlementPreferences).map(
                      paymentMethod =>
                        settlementPreferences?.[paymentMethod]?.enabled && (
                          <label key={paymentMethod} className="radio-button-field">
                            <div className="payment-icon">
                              <i className="fa fa-credit-card" />
                            </div>
                            <div
                              className={`radio-button ${paymentMode === paymentMethod ? 'w--redirected-checked' : ''}`}
                            />
                            <input
                              type="radio"
                              data-name="Payment Method"
                              id="Card"
                              name="Payment-Method"
                              onChange={() => {
                                this.setState({
                                  isAbleToTransact: false,
                                  isCheckoutSelected: paymentMode === 'by_cash',
                                  paymentModeValue: settlementPreferences[paymentMethod].value,
                                })
                                setCurrentPaymentMode(paymentMethod)
                              }}
                              style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                            />
                            <span className="label radio w-form-label">
                              {settlementPreferences[paymentMethod].label}
                            </span>
                          </label>
                        ),
                    )}
                    <div className="divider" />
                    <ExchangeRate {...this.state} showExchangeRate={this.showExchangeRate()} />
                    <div className="divider" />
                    <label className="w-checkbox checkbox-button-field">
                      <Checkbox
                        testId="transaction-confirm-personal-details"
                        top="4px"
                        onChange={() => this.setState({ isAbleToTransact: !isAbleToTransact })}
                        checked={isAbleToTransact}
                        onClick={this.handleGetTransactionDetails}
                      />
                      <span className="label checkbox-text w-form-label">
                        I {userAddressDetails?.full_name || 'the Customer'} confirm that all{' '}
                        <span
                          onClick={() => this.showModal('personalDetails')}
                          style={{ color: '#E60106', cursor: 'pointer', textDecoration: 'underline' }}>
                          information provided
                        </span>{' '}
                        by myself is correct and that ShopriteSend will be notified of any changes.
                      </span>
                    </label>
                    <button
                      className="w-100 cta-button w-button"
                      onClick={this.handleAcceptTransaction}
                      disabled={!(isCheckoutSelected && isAbleToTransact)}
                      data-testid="transfer-details-submit-payment-button"
                      style={{ marginTop: '20px' }}>
                      {isCheckoutSelected || paymentMode === 'by_cash' ? 'Accept Transaction' : 'Please Wait...'}
                    </button>
                    <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                      Protect yourself against scams. For more information click{' '}
                      <span
                        onClick={() => this.showModal('scamInformation')}
                        style={{ color: '#002fff', cursor: 'pointer', textDecoration: 'underline' }}>
                        here
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Modals sections */}
        <OtpModal
          show={modal.otp}
          onHide={() => this.hideModal('otp')}
          errors={errors?.otp}
          onSubmit={otp => this.handleTransactionConfirmation(otp)}
        />
        {modal.peachPayment && (
          <PeachPaymentModal {...this.state} show={modal.peachPayment} onHide={() => this.hideModal('peachPayment')} />
        )}
        {modal.ozow && <Ozow {...this.state} show={modal.ozow} onHide={() => this.hideModal('ozow')} />}
        <SameCurrencyModal
          show={modal.sameCurrency}
          onAccept={this.onAcceptanceOfOneToOneDeal}
          onHide={this.props.reloadComponent}
          onDecline={this.props.reloadComponent}
        />
        {modal.beneficiaryForm && (
          <BeneficiaryFormModal
            show={modal.beneficiaryForm}
            onHide={() => this.hideModal('beneficiaryForm')}
            onSuccess={this.onNewBeneficiaryAdded}
            isNewBeneficiary
          />
        )}

        {modal.transactionConfirmed && (
          <TransactionConfirmedModal
            {...this.state}
            show={modal.transactionConfirmed}
            onHide={() => {
              this.props.history.push(NEW_FULL_PATH + '-')
            }}
          />
        )}
        <TransactionProcessing show={modal.transactionProcessing} />
        <QuoteFailed
          {...this.state}
          show={modal.quoteFailed}
          onHide={() => this.hideModal('quoteFailed')}
          confirmTransaction={this.confirmTransaction}
        />
        {modal.personalDetails && (
          <Modal show={modal.personalDetails} className="personal-det-confirm">
            <PersonalDetailsConfirm {...this.state} onHide={() => this.hideModal('personalDetails')} />
          </Modal>
        )}
        {modal.scamInformation && (
          <Modal show={modal.scamInformation} className="personal-det-confirm">
            <div style={{ padding: '2rem' }}>
              <h5>Protect yourself</h5>
              <h6>Protect yourself against scams</h6>
              <p>
                Never give your transaction reference number to anyone other than the person you intend to receive the
                money.
              </p>

              <p>Personal information that you share on social media could be used by scammers to commit fraud.</p>

              <p>
                Sending money to anyone that you have not met in person, or in response to an online advertisement,
                poses a fraud risk.
              </p>

              <h6>Signs of a possible scam:</h6>

              <ul>
                <li>Where you are asked to send money to pay (for example):</li>

                <li>To receive a prize or lottery winnings</li>

                <li>To obtain credit or a loan</li>

                <li>A charity</li>

                <li>Someone you don’t know</li>
              </ul>
              <button
                onClick={() => this.hideModal('scamInformation')}
                type="button"
                style={{ height: '50px', width: '100%' }}
                className="w-inline-block-button bg-white mt-4">
                Close
              </button>
            </div>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {
    settings,
    currencies,
    currentCounterCurrency,
    currentReceiptPaymentMethod,
    currentPaymentMode,
  } = state.trading.data
  const { userDetails } = state.user.data
  const { tenant } = state.client.data
  return {
    currentCounterCurrency,
    currentReceiptPaymentMethod,
    isApplicationBank: tenant?.is_bank,
    paymentMode: currentPaymentMode?.paymentMode,
    tradingCurrencies: currencies,
    tradingSettings: settings,
    userAddressDetails: userDetails?.address,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      setCurrentBeneficiary,
      setCurrentCounterCurrency,
      setCurrentPaymentMode,
      setCurrentReceiptPaymentMethod,
      setCurrentTradingLimit,
      setTradingSettings,
    },
    dispatch,
  ),
})

NewPayment.propTypes = {
  currentCounterCurrency: PropTypes.string,
  currentPaymentMode: PropTypes.shape({
    counterCurrencyCode: PropTypes.string,
    counterCurrencyCountryCode: PropTypes.string,
  }),
  currentReceiptPaymentMethod: PropTypes.object,
  currentUser: PropTypes.shape({
    /** Account's ID associated to the user */
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  displayPage: PropTypes.number,
  goNextPage: PropTypes.func,
  history: PropTypes.object.isRequired,
  isApplicationBank: PropTypes.bool,
  /** Provides several attributes to get the current url {@link withRouter} */
  location: PropTypes.object.isRequired,
  paymentMode: PropTypes.string,
  reloadComponent: PropTypes.func.isRequired,
  setCurrentBeneficiary: PropTypes.func.isRequired,
  setCurrentCounterCurrency: PropTypes.func.isRequired,
  setCurrentPaymentMode: PropTypes.func.isRequired,
  setCurrentReceiptPaymentMethod: PropTypes.func.isRequired,
  setCurrentTradingLimit: PropTypes.func.isRequired,
  setTradingSettings: PropTypes.func.isRequired,
  tradingCurrencies: PropTypes.exact({
    buy: PropTypes.arrayOf(PropTypes.object),
    sell: PropTypes.arrayOf(PropTypes.object),
  }),
  tradingSettings: PropTypes.shape({
    beneficiary_payments: PropTypes.shape({
      approvals_enabled: PropTypes.bool,
      approvals_required: PropTypes.number,
    }),
    same_currency_transaction: PropTypes.shape({
      enabled: PropTypes.bool,
      prompt_on_same_currencies_selection: PropTypes.bool,
    }),
  }),
  userAddressDetails: PropTypes.shape({
    full_name: PropTypes.string,
    user_id: PropTypes.number,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithCurrentUser(NewPayment)))
